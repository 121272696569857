body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --primary-text-color: #0000ff;
  --secondary-text-color: #d2b48c;
  --complimentary-text-color: #5DCBD0;
}

