.project-container {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
}

.project-card {
    margin: 10px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    width: 300px;
    height: 60vh;
}

.project-card:hover {
    background-color: #eeeeee;
}

.project-card-image img {
    width: 100%;
    height: 30vh;
    object-fit: cover;
}

.project-card-tag {
    display: flex;
    flex-direction: row;
    padding-top: 1.8rem;
    padding-left: 1.8rem;
}

.tag {
    border-radius: 30px;
    font-size: 0.75rem;
    color: #ffffff;
    padding: 0.125rem 0.625rem;
    text-transform: uppercase;
    margin-left: 0.3rem;
}

.tag:first-child {
    margin-left: 0;
}

/* Color styling for tags  */

.tag-teal {
    background-color: #47bcd4;
}

.tag-black {
    background-color: #000000;
}

.tag-green {
    background-color: #329246;
}

.tag-coral {
    background-color: #F77D5E;
}

.tag-yellow {
    background-color: #E0C84A;
}

.tag-purple {
    background-color: #800080;
}

.tag-orange {
    background-color: #FF5733;
}

.tag-pink {
    background-color: #FF1493;
}

.tag-navy {
    background-color: #000080;
}

.project-card-content {
    display: flex;
    flex-wrap: wrap;
    padding: 0 1.8rem;
}

.project-card-content .logo img {
    height: 40px;
    width: 30px;
}

.project-card-content .logo img:hover {
    transition: 0.7s;
    transform: scale(1.5);
}

.project-card-content .logo:last-child {
    margin-left: 11.175rem;
}

/* Media queries  */

@media screen and (max-width: 425px) {
    .project-container {
        justify-content: center;
        flex-wrap: wrap;
    }

    .project-card {
        height: fit-content;
    }
}

/* @media screen and (min-width: 1280px) {
    .project-card {
      height: 52vh;
      margin: 0 20px;
    }
} */