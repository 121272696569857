@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');

.contact-container {
    display: flex;
    font-family: 'Poppins', 'sans-serif';
}

.contact-info {
    display: flex;
    flex-direction: column;
    height: fit-content;
    border-radius: 10px;
}

.contact-card {
    align-self: center;
}

.divider {
    border: 2px solid var(--secondary-text-color);
}

.contact-card img {
    display: inline-block;
    height: 50px;
    width: 40px;
}

h2 {
    color: var(--primary-text-color);
}

.contact-card-text {
    margin: auto;
}

button {
    display: contents;
    cursor: pointer;
}

.link,
.resume {
    text-decoration-line: none;
    cursor: pointer;
}

.resume img {
    margin-left: 2rem;
}

@media screen and (max-width: 425px) {
    h2 {
        text-align: center;
    }
}