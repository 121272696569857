@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');

.container {
    z-index: 1;
    width: fit-content;
    display: flex;
    flex-direction: row;
}

.about-content {
    display: flex;
    flex-direction: column;
    width: 40%;
}

h1 {
    font-size: 2rem;
    animation: drop-in 2000ms;
}

@keyframes drop-in {
    from {
      transform:
        rotate(-30deg) translateY(-100%);
      opacity: 0;
    }
    to {
      transform:
        rotate(0deg) translateY(0%);
      opacity: 1;
    }
  }

h1,
.about-text {
    margin: 0;
    color: var(--primary-text-color);
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
}

.about-text {
    font-size: 1rem;
    margin-top: 0.5rem;
}

.profile-image {
    width: 25vw;
    height: 52vh;
    margin-top: 4rem;
    margin-left: 14rem;
    border-radius: 3px;
}

/* Media queries  */

@media screen and (max-width: 425px) {
    .container {
        flex-direction: column;
    }

    .about-content {
        width: 100%;
    }

    .profile-image {
        margin-top: 2rem;
        margin-left: 3rem;
        height: auto;
        width: 55vw;
    }
}