@import './index.css';
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');

body {
  font-family: 'Poppins', sans-serif;
}

.wrapper {
  z-index: 1;
  font-weight: 300;
}

#tsparticles {
  position: fixed;
  width: 100%;
  z-index: -1;
}

.nav {
  display: flex;
  align-items: center;
  position: -webkit-sticky;
  position: sticky;
  z-index: 2;
  height: 70px;
  min-height: 70px;
  box-shadow: 0 0.125rem 0.25rem 0 rgb(0 0 0 / 11%);
  color: var(--secondary-text-color);
  border-bottom: 1px solid white;
  border-radius: 5px;
  background-color: white;
  top: 0;
  font-weight: bold;
}

.nav-container {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  position: relative;
}

.nav-container-actions {
  display: inline-flex;
  flex-flow: row nowrap;
  padding-left: 35px;
  flex: 1;
}

.active {
  border-bottom: 1px solid var(--secondary-text-color);
}

ul {
  display: flex;
  gap: 1rem;
  font-size: 1rem;
  list-style: none;
  padding: 0;
}

li {
  cursor: pointer;
}

li:hover {
  font-weight: bold;
}

.link-github {
  color: #000000;
  text-decoration: none;
  margin-right: 1rem;
  margin-left: 0.5rem;
  font-weight: 100;
}

section {
  display: flex;
  height: 100vh;
}

#about {
  margin: 2rem 0rem 0rem 2rem;
}

#contact {
  justify-content: center;
}

/* Media queries  */

@media screen and (max-width: 425px) {

.nav-container-actions {
  padding-left: 34px;
}

ul, li, .link-github {
  font-size: 1rem;
}

.link-github {
  margin-left: 3rem;
}

ul {
  gap: 0.7rem;
}

  #about {
    margin-bottom: 12rem;
  }

  #contact {
    margin-top: 72rem;
  }
}

@media screen and (max-width: 380px) {
  #about {
    margin-bottom: 18rem;
  }
}

@media screen and (min-width: 1280px) {
    #projects {
      justify-content: center;
    }
}